<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        © 2020
        <strong>{{ $t("ukfNitra") }}.</strong>
        {{ $t("rights_reserved") }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  padding: 24px 0;
  background-color: white;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{staticClass:"column is-narrow card-container",attrs:{"to":{ name: 'showEvent', params: { eventId: this.eventId } }}},[_c('div',{staticClass:"panel _card"},[_c('div',{staticClass:"panel-heading",class:{
        eventBackColorFPV: _vm.eventIdFaculty == 1,
        eventBackColorFF: _vm.eventIdFaculty == 4,
        eventBackColorFSS: _vm.eventIdFaculty == 3,
        eventBackColorFP: _vm.eventIdFaculty == 5,
        eventBackColorFSVZ: _vm.eventIdFaculty == 2,
        eventBackColorUKF: _vm.eventIdFaculty == 6,
        eventBackColorLIB: _vm.eventIdFaculty == 7
      }},[(_vm.eventName)?_c('p',{staticClass:"alignLeft"},[(_vm.eventName.length <= 11)?_c('span',[_vm._v(" "+_vm._s(_vm.eventName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.shortenName)+" ")])]):_vm._e(),_c('p',{staticClass:"alignRight",staticStyle:{"font-style":"italic","font-size":"15px","padding-top":"5px"}},[_vm._v(" "+_vm._s(_vm.eventFaculty.name)+" ")]),_c('div',{staticStyle:{"clear":"both"}})]),_c('div',{staticClass:"panel-block",staticStyle:{"padding":"0","max-height":"230px","overflow-y":"hidden"}},[(_vm.titleImage)?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.getImgUrl(_vm.titleImage),"alt":"alternatetext"}}):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://www.nitralive.sk/images/stories/vystavba/ukf/laboratoria/laboratoria-ukf-nitra-vizualizacia-titulka.jpg","alt":"alternatetext"}})]),_c('div',{staticClass:"quickDetailsHeader",class:{
        eventBackColorFPV: _vm.eventIdFaculty == 1,
        eventBackColorFF: _vm.eventIdFaculty == 4,
        eventBackColorFSS: _vm.eventIdFaculty == 3,
        eventBackColorFP: _vm.eventIdFaculty == 5,
        eventBackColorFSVZ: _vm.eventIdFaculty == 2,
        eventBackColorUKF: _vm.eventIdFaculty == 6,
        eventBackColorLIB: _vm.eventIdFaculty == 7
      }},[_c('h1',{staticClass:"alignLeft"},[_c('strong',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.eventDateSplit)+" ")])]),_c('h1',{staticClass:"alignRight",staticStyle:{"font-style":"italic","color":"white"}},[_vm._v(" "+_vm._s(_vm.eventTimeSplit)+" ")]),_c('br'),_c('h1',{staticClass:"alignLeft"},[(_vm.eventDepartment)?_c('strong',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.eventDepartment.name)+" ")]):_c('strong',{staticStyle:{"color":"white"}},[_vm._v(" Vsetky katedry ")])]),_c('h1',{staticClass:"alignRight",staticStyle:{"color":"white","font-style":"italic"}},[_vm._v(" "+_vm._s(_vm.eventPlace.name)+" ")]),_c('div',{staticStyle:{"clear":"both"}})]),(_vm.eventDesc)?_c('div',{staticClass:"eventDetails",class:{
        eventBorderColorFPV: _vm.eventIdFaculty == 1,
        eventBorderColorFF: _vm.eventIdFaculty == 4,
        eventBorderColorFSS: _vm.eventIdFaculty == 3,
        eventBorderColorFP: _vm.eventIdFaculty == 5,
        eventBorderColorFSVZ: _vm.eventIdFaculty == 2,
        eventBorderColorUKF: _vm.eventIdFaculty == 6,
        eventBorderColorLIB: _vm.eventIdFaculty == 7
      },staticStyle:{"overflow-y":"scroll"}},[_c('p',[_vm._v(" "+_vm._s(_vm.eventDesc)+" ")])]):_c('div',{staticClass:"eventDetails",class:{
        eventBorderColorFPV: _vm.eventIdFaculty == 1,
        eventBorderColorFF: _vm.eventIdFaculty == 4,
        eventBorderColorFSS: _vm.eventIdFaculty == 3,
        eventBorderColorFP: _vm.eventIdFaculty == 5,
        eventBorderColorFSVZ: _vm.eventIdFaculty == 2,
        eventBorderColorUKF: _vm.eventIdFaculty == 6,
        eventBorderColorLIB: _vm.eventIdFaculty == 7
      },staticStyle:{"overflow-y":"scroll"}},[_c('p',{staticStyle:{"color":"#adadad"}},[_vm._v(" Bez popisu. ")])]),_c('hr',{staticStyle:{"margin":"0px"}}),_c('div',{staticClass:"eventTags"},[_c('b-taglist',_vm._l((_vm.eventCategories),function(tag){return _c('b-tag',{key:tag.id,staticClass:"has-text-white",class:{
            eventBackColorFPV: _vm.eventIdFaculty == 1,
            eventBackColorFF: _vm.eventIdFaculty == 4,
            eventBackColorFSS: _vm.eventIdFaculty == 3,
            eventBackColorFP: _vm.eventIdFaculty == 5,
            eventBackColorFSVZ: _vm.eventIdFaculty == 2,
            eventBackColorUKF: _vm.eventIdFaculty == 6,
            eventBackColorLIB: _vm.eventIdFaculty == 7
          },attrs:{"size":"is-medium"}},[_vm._v(" #"+_vm._s(tag.name)+" ")])}),1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }